<template>
  <div>
    <Slides v-bind:scrollingCards="scrollingCards" />
    <v-btn
      :href="$t('resume.fileName')"
      :download="$t('resume.fileName')"
      color="primary"
      class="white--text"
      fab
      x-large
      fixed
      right
      bottom
    >
      <v-icon>
        mdi-download
      </v-icon>
    </v-btn>
    <!-- <v-btn class="align-center justify-center" style="" :href="$t('resume.fileName')" :download="$t('resume.fileName')">{{ $t('resume.download') }}</v-btn> -->
    <CV />
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script>
<script>
import Slides from "@/components/Slides";
import CV from "@/components/CV";
// import html2canvas from "html2canvas";

// const { jsPDF } = require("jspdf");

export default {
  name: "resume",

  components: { Slides, CV },

  methods: {
  // ### Use jsPDF and html2Canvas to create a pdf from the website html
  // ### currently on hold for layout problems
  //   convert() {
  //     let pdf = new jsPDF("p", "pt", "a4");
  //     let source = window.document.getElementById("cv").innerHTML;

  //     window.html2canvas = html2canvas;

  //     if (source) {
  //       console.log("div is ");
  //       console.log(source);
  //       console.log("hellowww");

  //       pdf.html(source, {
  //         margin: [0,0,0,0],
  //         html2canvas: {
  //           // insert html2canvas options here, e.g.
  //           windowWidth: 400,
  //           width: 400,
  //         },
  //         callback: function(pdf) {
  //           pdf.save("DOC.pdf");
  //         },
  //       });
  //     }
  //   },
  },

  data: () => ({
    // Description of the Cards to show in the page
    scrollingCards: [
      {
        id: "1",
        class: "secondary elevation-0",
        layoutColor: "",
        height: "50vh",
        image: "none",
        imageClasses: "",
        justify: "space-around",
        left: {
          order: "",
          isOn: true,
          cols: 10,
          md: 10,
          lg: 10,
          xl: 10,
          textCardContainerClasses: "elevation-0",
          textCardContainerColor: "transparent",
          title: "resume.slide1.title",
          titleClasses: "text-no-wrap text-left primary--text text-size-1 font-weight-light",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
        },
        right: {
          order: "",
          isOn: false,
          cols: 10,
          md: 5,
          lg: 6,
          xl: 7,
          textCardContainerClasses: "",
          textCardContainerColor: "",
          title: "none",
          titleClasses: "",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
        },
      },
      {
        id: "2",
        class: "",
        // height: "70vh",
        image: require("../assets/do_not_print.webp"),
        imageClasses: "",
        justify: "space-around",
        left: {
          order: "",
          orderMd: "",
          isOn: false,
          cols: 10,
          md: 5,
          lg: 4,
          xl: 3,
          textCardContainerClasses: "",
          textCardContainerColor: "",
          title: "none",
          titleClasses: "",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
        },
        right: {
          order: "",
          orderMd: "",
          isOn: false,
          cols: 10,
          md: 5,
          lg: 4,
          xl: 3,
          textCardContainerClasses: "",
          textCardContainerColor: "",
          title: "none",
          titleClasses: "",
          subtitle: "none",
          subtitleClasses: "",
          content: "none",
          contentClasses: "",
        },
      },
    ],
  }),
};
</script>
