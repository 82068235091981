<template>
  <div>
    <table class="c19">
      <tbody>
        <tr class="c21">
          <v-row no-gutters justify="space-around">
            <v-col class="pa-0 secondary" cols="12" md="8">
              <td class="c7 secondary" colspan="1" rowspan="1">
                <p class="c15 title" id="h.x8fm1uorkbaw">
                  <span class="c17 primary--text">{{ $t("resume.cv.leftcol.name") }}</span>
                </p>
                <p class="c30 subtitle" id="h.ymi089liagec">
                  <span class="c2 contrast--text"
                    >{{ $t("resume.cv.leftcol.goal") }}
                  </span>
                </p>
                <h1 class="c18" id="h.wyvi55dsi7lc">
                  <span class="c3 c9 primary--text">{{ $t("resume.cv.leftcol.experience") }}</span>
                </h1>
                <h2 class="c25" id="h.rfgvkg2ifhfd">
                  <span class="c2 contrast--text font-weight-bold"
                    >{{ $t("resume.cv.leftcol.emirates") }}</span
                  ><span class="c2 contrast--text">{{ $t("resume.cv.leftcol.dubai") }} &mdash; </span
                  ><span class="c2 contrast--text c32">{{ $t("resume.cv.leftcol.cc") }}</span>
                </h2>
                <h3 class="c12" id="h.n64fgzu3lwuy">
                  <span class="c14 c2 contrast--text"
                    >{{ $t("resume.cv.leftcol.emiratestime") }}</span
                  >
                </h3>
                <p class="c1 subtitle" id="h.ymi089liagec-1">
                  <span class="c6 contrast--text">&bull; {{ $t("resume.cv.leftcol.peopleskill") }}</span
                  ><span class="c3 c2 contrast--text"
                    >&nbsp; {{ $t("resume.cv.leftcol.peopleskillcontent") }}
                  </span>
                </p>
                <p class="c1 subtitle" id="h.6zo2a6dmpjsg">
                  <span class="c6 contrast--text">&bull; {{ $t("resume.cv.leftcol.teamwork") }}</span
                  ><span class="c3 c2 contrast--text"
                    >{{ $t("resume.cv.leftcol.teamworkcontent") }}
                  </span>
                </p>
                <p class="c1 subtitle" id="h.145lji20g8lz">
                  <span class="c6 contrast--text">&bull; {{ $t("resume.cv.leftcol.timemanagement") }}</span
                  ><span class="c3 c2 contrast--text"
                    >&nbsp;{{ $t("resume.cv.leftcol.timemanagementcontent") }}
                  </span>
                </p>
                <p class="c1 subtitle" id="h.aniqt9umh3y8">
                  <span class="c6 contrast--text"
                    >&bull; {{ $t("resume.cv.leftcol.compliance") }}</span
                  ><span class="c3 c2 contrast--text"
                    >&nbsp;{{ $t("resume.cv.leftcol.compliancecontent") }}</span>
                </p>
                <h2 class="c25" id="h.wj0puh61kxsr">
                  <span class="c2 contrast--text font-weight-bold"
                    >{{ $t("resume.cv.leftcol.ow") }}</span
                  ><span class="c2 contrast--text">{{ $t("resume.cv.leftcol.milan") }}&mdash; </span
                  ><span class="c16 c2 contrast--text">{{ $t("resume.cv.leftcol.dev") }}</span>
                </h2>
                <h3 class="c12" id="h.8hk593fs3sag">
                  <span class="c14 c2 contrast--text"
                    >{{ $t("resume.cv.leftcol.owtime") }}</span
                  >
                </h3>
                <p class="c1 subtitle" id="h.ysl2eu5pxnbl">
                  <span class="c6 contrast--text">&bull; {{ $t("resume.cv.leftcol.itskill") }}</span
                  ><span class="c3 c2 contrast--text"
                    >{{ $t("resume.cv.leftcol.itskillcontent") }}
                  </span>
                </p>
                <p class="c1 subtitle" id="h.mldghpnbi671">
                  <span class="c6 contrast--text">&bull; {{ $t("resume.cv.leftcol.taskmanagementskill") }}</span
                  ><span class="c3 c2 contrast--text"
                    >{{ $t("resume.cv.leftcol.taskmanagementskillcontent") }}</span
                  >
                </p>
                <h2 class="c25" id="h.7zh8mo471u17">
                  <span class="c2 contrast--text font-weight-bold"
                    >{{ $t("resume.cv.leftcol.unibo") }}</span
                  ><span class="c2 contrast--text">{{ $t("resume.cv.leftcol.unibo") }}&mdash; </span
                  ><span class="c2 contrast--text c16"
                    >{{ $t("resume.cv.leftcol.intern") }}</span
                  >
                </h2>
                <h3 class="c12" id="h.65aiubqv0qky">
                  <span class="c14 c2 contrast--text"
                    >{{ $t("resume.cv.leftcol.interntime") }}</span
                  >
                </h3>
                <p class="c1 subtitle" id="h.68cg9t1v41db">
                  <span class="c6 contrast--text">&bull; {{ $t("resume.cv.leftcol.subject") }}</span
                  ><span class="c3 c2 contrast--text"
                    >&nbsp;{{ $t("resume.cv.leftcol.subjectcontent") }}
                  </span>
                </p>
                <p class="c1 subtitle" id="h.o7dzsa7ibfpt">
                  <span class="c6 contrast--text">&bull; {{ $t("resume.cv.leftcol.itskill") }}</span
                  ><span class="c3 c2 contrast--text"
                    >{{ $t("resume.cv.leftcol.internitskillcontent") }}
                  </span>
                </p>
                <p class="c1 subtitle" id="h.jp2gabz0mf1y">
                  <span class="c6 contrast--text">&bull; {{ $t("resume.cv.leftcol.taskmanagementskill") }}</span
                  ><span class="c2 contrast--text c3"
                    >&nbsp;{{ $t("resume.cv.leftcol.interntaskmanagementskillcontent") }}
                  </span>
                </p>
                <h2 class="c25" id="h.1hxcpsc1hco2">
                  <span class="c2 contrast--text font-weight-bold"
                    >{{ $t("resume.cv.leftcol.rubi") }}</span
                  ><span class="c2 contrast--text">{{ $t("resume.cv.leftcol.cesena") }}&mdash; </span
                  ><span class="c16 c2 contrast--text">{{ $t("resume.cv.leftcol.waiter") }}</span>
                </h2>
                <h3 class="c12" id="h.ybypdmed418m">
                  <span class="c2 contrast--text c14"
                    >{{ $t("resume.cv.leftcol.rubitime") }}</span
                  >
                </h3>
                <p class="c1 subtitle" id="h.zhoqvjo7z2iw">
                  <span class="c6 contrast--text">&bull; {{ $t("resume.cv.leftcol.attitudeskill") }}</span
                  ><span class="c3 c2 contrast--text"
                    >&nbsp;{{ $t("resume.cv.leftcol.attitudeskillcontent") }}
                  </span>
                </p>
                <p class="c1 subtitle" id="h.dmq417rh2rgl">
                  <span class="c6 contrast--text">&bull; {{ $t("resume.cv.leftcol.peopleskill") }}</span
                  ><span class="c3 c2 contrast--text"
                    >&nbsp;{{ $t("resume.cv.leftcol.peopleskillcontent") }}</span
                  >
                </p>
                <h1 class="c18" id="h.gm522czeqcgc">
                  <span class="c3 c9 primary--text">{{ $t("resume.cv.leftcol.education") }}</span>
                </h1>
                <h2 class="c25" id="h.6wymnhinx9q5">
                  <span class="c2 contrast--text font-weight-bold"
                    >{{ $t("resume.cv.leftcol.unibo") }}</span
                  ><span class="c2 contrast--text">{{ $t("resume.cv.leftcol.cesena") }}&mdash; </span
                  ><span class="c16 c2 contrast--text">{{ $t("resume.cv.leftcol.computerscience") }}</span>
                </h2>
                <h3 class="c12" id="h.7vtcyzeczjot">
                  <span class="c14 c2 contrast--text"
                    >{{ $t("resume.cv.leftcol.studytime") }}</span
                  >
                </h3>
                <p class="c1 subtitle" id="h.hb6rl4sr7kov">
                  <span class="c6 contrast--text">&bull; {{ $t("resume.cv.leftcol.attitudeskill") }}</span
                  ><span class="c3 c2 contrast--text"
                    >&nbsp;{{ $t("resume.cv.leftcol.studyattitudeskillcontent") }}</span
                  >
                </p>
                <p class="c1 subtitle" id="h.tzvkrtfplntm">
                  <span class="c6 contrast--text">&bull; {{ $t("resume.cv.leftcol.itskill") }}</span
                  ><span class="c3 c2 contrast--text"
                    >&nbsp;{{ $t("resume.cv.leftcol.studyitskillcontent") }}
                  </span>
                </p>
                <p class="c1 subtitle" id="h.11p1xdym0v6w">
                  <span class="c6 contrast--text">&bull; {{ $t("resume.cv.leftcol.peopleskill") }}</span
                  ><span class="c2 contrast--text"
                    >&nbsp;{{ $t("resume.cv.leftcol.studypeopleskillcontent") }}</span
                  >
                </p>
              </td>
            </v-col>
            <v-col class="pa-0 tertiary" cols="12" md="4">
              <td class="c34 tertiary" colspan="1" rowspan="1">
                <!-- <p class="c5">
                  <span class="c3 c2 contrast--text">{{ $t("resume.cv.rightcol.address") }}</span>
                </p>
                <p class="c5">
                  <span class="c3 c2 contrast--text">{{ $t("resume.cv.leftcol.dubai") }}</span>
                </p> -->
                <p class="c5">
                  <span class="c3 c6 contrast--text">{{ $t("resume.cv.rightcol.phone1") }}</span>
                </p>
                <p class="c5">
                  <span class="c3 c6 contrast--text">{{ $t("resume.cv.rightcol.phone2") }}</span>
                </p>
                <p class="c5">
                  <span class="c3 c6 contrast--text"
                    >{{ $t("resume.cv.rightcol.email") }}</span
                  >
                </p>
                <a id="t.7b193277cb4b3c4a71a5ab7cba9e83d72fd73b7e"></a
                ><a id="t.1"></a>
                <table class="c19">
                  <tbody>
                    <tr class="c13">
                      <td class="c26" colspan="1" rowspan="1">
                        <h1 class="c18" id="h.3mbt74y27maz">
                          <span class="c3 c9 primary--text">{{ $t("resume.cv.rightcol.softskill") }}</span>
                        </h1>
                        <p class="c4">
                          <span class="c3 c2 contrast--text"
                            >{{ $t("resume.cv.rightcol.problemsolving") }}
                          </span>
                        </p>
                        <p class="c4">
                          <span class="c3 c2 contrast--text"
                            >{{ $t("resume.cv.rightcol.adaptability") }}</span>
                        </p>
                        <p class="c4">
                          <span class="c3 c2 contrast--text"
                            >{{ $t("resume.cv.rightcol.communication") }}
                          </span>
                        </p>
                        <p class="c4">
                          <span class="c3 c2 contrast--text">{{ $t("resume.cv.rightcol.teamwork") }}</span>
                        </p>
                        <p class="c4">
                          <span class="c3 c2 contrast--text"
                            >{{ $t("resume.cv.rightcol.listening") }}</span>
                        </p>
                        <p class="c4">
                          <span class="c2 contrast--text">{{ $t("resume.cv.rightcol.timemanagement") }}</span>
                        </p>
                        <h1 class="c18" id="h.14s2x9j52n4p">
                          <span class="c3 c9 primary--text">{{ $t("resume.cv.rightcol.hardskill") }}</span>
                        </h1>
                        <p class="c4">
                          <span class="c3 c2 contrast--text">{{ $t("resume.cv.rightcol.java") }}</span>
                        </p>
                        <p class="c4">
                          <span class="c3 c2 contrast--text">{{ $t("resume.cv.rightcol.js") }}</span>
                        </p>
                        <p class="c4">
                          <span class="c3 c2 contrast--text">{{ $t("resume.cv.rightcol.python") }}</span>
                        </p>
                        <p class="c4">
                          <span class="c3 c2 contrast--text"
                            >{{ $t("resume.cv.rightcol.analytical") }}</span>
                        </p>
                        <p class="c4">
                          <span class="c3 c2 contrast--text"
                            >{{ $t("resume.cv.rightcol.data") }}</span>
                        </p>
                        <h1 class="c18" id="h.cxxkes25b26">
                          <span class="c3 c9 primary--text">{{ $t("resume.cv.rightcol.lang") }}</span>
                        </h1>
                        <p class="c4">
                          <span class="c3 c2 contrast--text"
                            >{{ $t("resume.cv.rightcol.langcontent") }}</span
                          >
                        </p>
                        <h1 class="c18" id="h.g7l7bsungaax">
                          <span class="c3 c9 primary--text"
                            >{{ $t("resume.cv.rightcol.certifications") }}</span
                          >
                        </h1>
                        <p class="c4">
                          <span class="c3 c2 contrast--text"
                            >{{ $t("resume.cv.rightcol.toefl") }}</span
                          >
                        </p>
                        <p class="c4">
                          <span class="c3 c2 contrast--text"
                            >{{ $t("resume.cv.rightcol.ibm") }}</span
                          >
                        </p>
                        <h1 class="c18" id="h.3imxae04f42">
                          <span class="c3 c9 primary--text">{{ $t("resume.cv.rightcol.website") }}</span>
                        </h1>
                        <p class="c4">
                          <span class="c3 c2 contrast--text"
                            >{{ $t("resume.cv.rightcol.url") }}</span
                          >
                        </p>
                        <p class="c4">
                          <span
                            style="overflow: hidden; display: inline-block; margin: 0px 0px; border: 0px solid #000000; width: 136px; height: 174.10px;"
                            >
                            <img
                              alt=""
                              src="./image1.png"
                              style="width: 136px; height: 136px; margin-left: 0px; margin-top: 0px"
                              title=""
                          /></span>
                        </p>
                        <!-- <p class="c8 c10"><span class="c3 c11 c23"></span></p> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- <p class="c4 c10"><span class="c3 c29"></span></p> -->
              </td>
            </v-col>
          </v-row>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "CV",

  data: () => ({}),
};
</script>

<style>
@import url("https://themes.googleusercontent.com/fonts/css?kit=RFda8w1V0eDZheqfcyQ4EGb3DKsRMD34dqg1gT8Z-p6isjtAVhoKeKPV_uAAgBOSk3k702ZOKiLJc3WVjuplzHaYiPAslbK81rCSwRSnoRqS4b96hY1-3VJ9ZmMXwl4S");
.lst-kix_d52h1clnbs3c-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_d52h1clnbs3c-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_d52h1clnbs3c-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_d52h1clnbs3c-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_d52h1clnbs3c-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_d52h1clnbs3c-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_d52h1clnbs3c-0 > li:before {
  content: "\00200b  ";
}
.lst-kix_d52h1clnbs3c-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_3expns8knkd9-0 > li:before {
  content: "\00200b  ";
}
.lst-kix_3expns8knkd9-1 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_d52h1clnbs3c-5 {
  list-style-type: none;
}
ul.lst-kix_d52h1clnbs3c-6 {
  list-style-type: none;
}
.lst-kix_3expns8knkd9-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_d52h1clnbs3c-7 {
  list-style-type: none;
}
ul.lst-kix_d52h1clnbs3c-8 {
  list-style-type: none;
}
.lst-kix_3expns8knkd9-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_d52h1clnbs3c-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_3expns8knkd9-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_3expns8knkd9-7 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_d52h1clnbs3c-0 {
  list-style-type: none;
}
ul.lst-kix_d52h1clnbs3c-1 {
  list-style-type: none;
}
ul.lst-kix_d52h1clnbs3c-2 {
  list-style-type: none;
}
ul.lst-kix_d52h1clnbs3c-3 {
  list-style-type: none;
}
ul.lst-kix_d52h1clnbs3c-4 {
  list-style-type: none;
}
.lst-kix_3expns8knkd9-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_3expns8knkd9-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_3expns8knkd9-6 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_3expns8knkd9-2 {
  list-style-type: none;
}
ul.lst-kix_3expns8knkd9-1 {
  list-style-type: none;
}
ul.lst-kix_3expns8knkd9-4 {
  list-style-type: none;
}
ul.lst-kix_3expns8knkd9-3 {
  list-style-type: none;
}
ul.lst-kix_3expns8knkd9-0 {
  list-style-type: none;
}
ul.lst-kix_3expns8knkd9-6 {
  list-style-type: none;
}
ul.lst-kix_3expns8knkd9-5 {
  list-style-type: none;
}
ul.lst-kix_3expns8knkd9-8 {
  list-style-type: none;
}
ul.lst-kix_3expns8knkd9-7 {
  list-style-type: none;
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c7 {
  border-right-style: solid;
  padding: 59.5pt 43.2pt 59.5pt 43.2pt;
  /* border-bottom-color: #ffffff; */
  border-top-width: 0pt;
  border-right-width: 0pt;
  /* border-left-color: #ffffff; */
  vertical-align: top;
  /* border-right-color: #ffffff; */
  border-left-width: 0pt;
  border-top-style: solid;
  /* background-color: #12181c; */
  border-left-style: solid;
  border-bottom-width: 0pt;
  /* width: 66vw; */
  /* border-top-color: #ffffff; */
  border-bottom-style: solid;
}
.c34 {
  border-right-style: solid;
  padding: 59.5pt 43.2pt 3pt 43.2pt;
  /* border-bottom-color: #ffffff; */
  /* border-left-color: #ffffff; */
  vertical-align: top;
  /* border-right-color: #ffffff; */
  border-left-width: 0pt;
  border-top-style: solid;
  /* background-color: #1b2429; */
  border-left-style: solid;
  border-bottom-width: 0pt;
  /* width: 33vw; */
  /* border-top-color: #ffffff; */
  border-bottom-style: solid;
}
.c26 {
  border-right-style: solid;
  padding-bottom: 0pt !important;
  padding-top:7.2pt;
  /* border-bottom-color: #ffffff; */
  border-top-width: 0pt;
  border-right-width: 0pt;
  /* border-left-color: #ffffff; */
  vertical-align: top;
  /* border-right-color: #ffffff; */
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  /* width: 358.5pt; */
  /* border-top-color: #ffffff; */
  border-bottom-style: solid;
}
.c17 {
  /* color: #1976d2; */
  font-weight: 300;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 36pt;
  font-family: "Roboto";
  font-style: normal;
}
.c12 {
  padding-top: 5pt;
  padding-bottom: 5pt;
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
  margin-right: 6pt;
}
.c25 {
  padding-top: 16pt;
  padding-bottom: 0pt;
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
  margin-right: 6pt;
}
.c28 {
  padding-top: 6pt;
  padding-bottom: 0pt;
  line-height: 1.3;
  text-align: left;
  margin-right: 6pt;
}
.c20 {
  color: #666666;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 6pt;
  font-style: normal;
}
.c5 {
  padding-top: 0pt;
  padding-bottom: 6pt;
  line-height: 1.15;
  text-align: left;
  margin-right: 15pt;
  margin-bottom: 0pt !important;
}
.c1 {
  padding-top: 6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  margin-right: 6pt;
  margin-bottom: 0pt !important;
}
.c24 {
  padding-top: 30pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  margin-right: 15pt;
}
.c30 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  text-align: left;
  margin-right: 6pt;
  margin-bottom: 0pt !important;
}
.c15 {
  padding-top: 0pt;
  padding-bottom: 6pt;
  line-height: 1;
  text-align: left;
  margin-right: 6pt;
}
.c31 {
  padding-top: 6pt;
  padding-bottom: 0pt;
  line-height: 1.3;
  text-align: left;
  margin-right: 15pt;
}
.c8 {
  padding-top: 16pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  margin-right: 15pt;
}
.c18 {
  padding-top: 30pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  margin-right: 6pt;
}
.c4 {
  padding-top: 6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  margin-right: 15pt;
  margin-bottom: 0px !important;
}
.c22 {
  text-decoration: none;
  vertical-align: baseline;
  font-size: 6pt;
  font-style: normal;
}
.c16 {
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: italic;
}
.c3 {
  text-decoration: none;
  vertical-align: baseline;
  font-size: 9pt;
  font-style: normal;
}
.c19 {
  padding: 28.8pt 43.2pt 43.2pt 43.2pt;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}
.c14 {
  text-decoration: none;
  vertical-align: baseline;
  font-size: 8pt;
  font-style: normal;
}
.c2 {
  font-family: "Roboto";
  /* color: #ffffff; */
  font-weight: 400;
}
.c29 {
  color: #000000;
  font-weight: 700;
  font-family: "Roboto";
}
.c6 {
  font-family: "Roboto";
  /* color: #ffffff; */
  font-weight: 700;
}
.c9 {
  /* color: #2079c7; */
  font-weight: 700;
  font-family: "Roboto";
}
.c33 {
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
}
.c0 {
  margin: 0px;
  /* background-color: #1b2429;
    max-width: 525.6pt;
    padding: 28.8pt 43.2pt 43.2pt 43.2pt; */
}
.c11 {
  font-weight: 400;
  font-family: "Roboto";
}
.c23 {
  color: #666666;
}
.c32 {
  font-style: italic;
}
.c10 {
  height: 9pt;
}
.c21 {
  height: 80pt;
}
.c13 {
  height: 627.8pt;
}
.title {
  padding-top: 0pt;
  /* color: #000000; */
  /* font-weight: 700; */
  font-size: 36pt;
  padding-bottom: 6pt;
  /* font-family: "Merriweather"; */
  line-height: 1;
  text-align: left;
}
.subtitle {
  padding-top: 3pt;
  /* color: #000000; */
  font-size: 9pt;
  padding-bottom: 3pt;
  font-family: "Open Sans";
  line-height: 1.15;
  text-align: left;
}
li {
  color: #666666;
  font-size: 9pt;
  font-family: "Merriweather";
}
p {
  margin: 0;
  color: #666666;
  font-size: 9pt;
  font-family: "Merriweather";
}
h1 {
  padding-top: 30pt;
  color: #2079c7;
  font-weight: 700;
  font-size: 9pt;
  padding-bottom: 0pt;
  font-family: "Open Sans";
  line-height: 1;
  text-align: left;
}
h2 {
  padding-top: 16pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 0pt;
  font-family: "Merriweather";
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
h3 {
  padding-top: 5pt;
  color: #666666;
  font-size: 8pt;
  padding-bottom: 5pt;
  font-family: "Open Sans";
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
h4 {
  padding-top: 8pt;
  -webkit-text-decoration-skip: none;
  color: #666666;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font-size: 11pt;
  padding-bottom: 0pt;
  font-family: "Trebuchet MS";
  line-height: 1.3;
  page-break-after: avoid;
  text-align: left;
}
h5 {
  padding-top: 8pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 0pt;
  font-family: "Trebuchet MS";
  line-height: 1.3;
  page-break-after: avoid;
  text-align: left;
}
h6 {
  padding-top: 8pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 0pt;
  font-family: "Trebuchet MS";
  line-height: 1.3;
  page-break-after: avoid;
  font-style: italic;
  text-align: left;
}
</style>
